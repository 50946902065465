import { useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import Router from 'router/Router';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { getAccessTokenFromLS, getLangFromLS, setLangInLS } from 'utils/storageUtils';
import { changeContentLang } from 'store/content-slice/content-slice';
import { setAccessToken } from 'store/user-slice/user-slice';
import { DEFAULT_LANG } from 'contants/commonConstants';
import { REACT_APP_GOOGLE_CLIENTID_NEW, REACT_APP_GTM_ID } from 'contants/envConstants';
import Loader from 'components/Loaders/Loader';

import './styles/custom-theme.scss';
import './App.css';

function App() {
  const dispatch = useAppDispatch();
  const token = getAccessTokenFromLS();
  const auth = { token: token ? true : false }
  const { userLang } = useAppSelector((state) => state.content);

  useEffect(() => {
    if (REACT_APP_GTM_ID) {
      const tagManagerArgs: TagManagerArgs = { gtmId: REACT_APP_GTM_ID }
      TagManager.initialize({
        gtmId: REACT_APP_GTM_ID
      })
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  useEffect(() => {
    if (auth.token) {
      dispatch(setAccessToken(JSON.parse(token)));
    }
  }, [dispatch, auth?.token])

  useEffect(() => {
    const lang = getLangFromLS() || DEFAULT_LANG;
    dispatch(changeContentLang(lang));
    !getLangFromLS() && setLangInLS(lang);
  }, [dispatch]);

  return (
    <div className="App" dir={userLang === 'ar' ? 'rtl' : 'ltr'}>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENTID_NEW}>
        <Loader>
          <Router /> 
        </Loader>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
